import React, { useState } from 'react';
import { IShippingMethod } from 'components/Checkout/interfaces/IShippingMethod';
import LayoutSplit from 'ekaubamaja-ui/lib/Layouts/LayoutSplit';
import LayoutSplitColumn from 'ekaubamaja-ui/lib/Layouts/LayoutSplitColumn';
import ShippingMethods from 'components/Checkout/components/ShippingMethods';
import ShippingMethodAdditional from 'components/Checkout/components/ShippingMethodAdditional';
import LayoutForm from 'ekaubamaja-ui/lib/Layouts/LayoutForm';
import FormRow from 'ekaubamaja-ui/lib/Components/FormRow';
import ControlTextarea from 'ekaubamaja-ui/lib/Components/ControlTextarea';
import Buttons from 'ekaubamaja-ui/lib/Components/Buttons/index';
import Button from 'ekaubamaja-ui/lib/Components/Button/index';
import { closeOverlay, openOverlay } from 'data/actions/overlays';
import { useDispatch } from 'react-redux';
import ConfirmationOverlay from 'components/Checkout/components/ConfirmationOverlay';
import { useMutation } from 'redux-query-react';
import getPdfRequest from 'components/Checkout/requests/signing/general/getPdfRequest';
import { saveAs } from 'file-saver';
import { HttpStatusCode } from '../../../enums/HttpStatusCode';
import Toaster from 'ekaubamaja-ui/lib/Components/Toaster';
import { SubmitActionEnum } from 'components/CartView/interfaces/CartInterfaces';
import CheckboxWithLabel from 'ekaubamaja-ui/lib/Components/CheckboxWithLabel';
import AgreementsOverlay from 'components/Checkout/components/AgreementsOverlay';
import { formatPriceLocale } from '../../../helpers/priceUtils';

interface IProps {
    shippingMethods: IShippingMethod[];
    selectedShippingMethod: IShippingMethod;
    setSelectedShippingMethod: (method: IShippingMethod) => void;
    setShippingComment: (message: string) => void;
    submitAction: SubmitActionEnum;
    shippingComment: string;
    submitOrder: () => void;
    minOrderSum: number;
    requiredFieldsLabel: string;
    commentLabel: string;
    submitLabel: string;
    cancelLabel: string;
    notEnoughPermissionsLabel: string;
    shippingAddressLabel: string;
    registerNewsletter: boolean;
    setRegisterNewsletter: (boolean) => void;
    registerNewsletterLabel: string;
    isSubscribedToNewsletter: boolean;
    agreementContents: string;
    agreeToTermsLabel: string;
    cartNotOverMinimumTotalLabel: string;
    confirmationLabels: {
        overLayTitle: string;
        confirmSubmitting: string;
        smartId: string;
        mobileId: string;
        phoneNumber: string;
        personalCode: string;
        sign: string;
        downloadFile: string;
        verificationCode: string;
        signingFailed: string;
        idCard: string;
        signOrder: string;
    };
}

const ShippingContent: React.FunctionComponent<IProps> = (props) => {
    const {
        selectedShippingMethod,
        setSelectedShippingMethod,
        shippingMethods,
        requiredFieldsLabel,
        shippingComment,
        setShippingComment,
        submitOrder,
        minOrderSum,
        commentLabel,
        submitLabel,
        cancelLabel,
        confirmationLabels,
        notEnoughPermissionsLabel,
        shippingAddressLabel,
        submitAction,
        registerNewsletter,
        setRegisterNewsletter,
        registerNewsletterLabel,
        isSubscribedToNewsletter,
        agreementContents,
        agreeToTermsLabel,
        cartNotOverMinimumTotalLabel,
    } = props;

    const [{}, getPdf] = useMutation(() => getPdfRequest());
    const dispatch = useDispatch();
    const [termsAccepted, setTermsAccepted] = useState(false);

    const handleBasketSubmit = () => {
        switch (submitAction) {
            case SubmitActionEnum.NO_SIGNING:
                dispatch(closeOverlay({ name: 'all' }));
                dispatch(openOverlay({ name: 'basketSubmitConfirmOverlay' }));
                break;
            case SubmitActionEnum.DIGIDOC_SIGNING:
                dispatch(closeOverlay({ name: 'all' }));
                dispatch(openOverlay({ name: 'signingOverlay' }));
                break;
            case SubmitActionEnum.NOT_OVER_MINIMUM_GRAND_TOTAL:
                Toaster.addToast({
                    intent: 'danger',
                    text: cartNotOverMinimumTotalLabel.replace('%1', formatPriceLocale(minOrderSum.toString())),
                });
                break;
            case SubmitActionEnum.NOT_ALLOWED_TO_SIGN:
            case SubmitActionEnum.NOT_ALLOWED_TO_SUBMIT:
            default:
                Toaster.addToast({ intent: 'danger', text: notEnoughPermissionsLabel });
                break;
        }
    };

    const downloadFile = async () => {
        void getPdf().then((res) => {
            if (res.status === HttpStatusCode.OK) {
                saveAs(res?.body?.url, res?.body.fileName);
            } else {
                Toaster.addToast({ text: res.body.error, intent: 'danger' });
            }
        });
    };

    return (
        <>
            <ConfirmationOverlay
                submitOrder={submitOrder}
                confirmationLabels={confirmationLabels}
                submitLabel={submitLabel}
                cancelLabel={cancelLabel}
                downloadFile={downloadFile}
            />
            <p>{requiredFieldsLabel}</p>
            <LayoutSplit>
                <LayoutSplitColumn>
                    <ShippingMethods
                        selectedShippingMethod={selectedShippingMethod}
                        setSelectedShippingMethod={setSelectedShippingMethod}
                        shippingMethods={shippingMethods}
                    />
                </LayoutSplitColumn>
                <LayoutSplitColumn>
                    <ShippingMethodAdditional
                        selectedShippingMethod={selectedShippingMethod}
                        shippingAddressLabel={shippingAddressLabel}
                    />
                </LayoutSplitColumn>
            </LayoutSplit>
            <LayoutForm layout="vertical">
                <FormRow label={commentLabel}>
                    <ControlTextarea
                        value={shippingComment}
                        minRows={3}
                        onChange={(e) => {
                            setShippingComment(e.target.value);
                        }}
                    />
                </FormRow>
                <FormRow required={true}>
                    <CheckboxWithLabel
                        label={
                            <a
                                href="#"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                    dispatch(openOverlay({ name: 'agreements' }));
                                }}
                            >
                                {agreeToTermsLabel}
                            </a>
                        }
                        checked={termsAccepted}
                        onChange={() => {
                            setTermsAccepted(!termsAccepted);
                        }}
                    />
                    <AgreementsOverlay
                        agreementContents={agreementContents}
                        titleLabel={agreeToTermsLabel}
                        closeLabel={cancelLabel}
                    />
                    {!isSubscribedToNewsletter && (
                        <CheckboxWithLabel
                            label={registerNewsletterLabel}
                            checked={registerNewsletter}
                            onChange={() => {
                                setRegisterNewsletter(!registerNewsletter);
                            }}
                        />
                    )}
                </FormRow>
            </LayoutForm>
            <Buttons layout="vertical-wide">
                <Button
                    title={submitLabel}
                    disabled={!termsAccepted}
                    onClick={() => {
                        handleBasketSubmit();
                    }}
                />
            </Buttons>
        </>
    );
};

export default ShippingContent;
