import React, { useState } from 'react';
import IProduct from 'components/Catalog/interfaces/IProduct';
import Img from 'components/Image/Img';
import useAuth from '../../../../../hooks/useAuth';
import { useDispatch } from 'react-redux';
import { closeOverlay, openOverlay } from 'data/actions/overlays';
import Button from 'ekaubamaja-ui/lib/Components/Button';
import { HttpStatusCode } from '../../../../../enums/HttpStatusCode';
import AddToCartRequest, { IAddToCartRequestData } from 'components/Catalog/Product/requests/AddToCartRequest';
import { useMutation } from 'redux-query-react';
import Toaster from 'ekaubamaja-ui/lib/Components/Toaster/index';
import ProductPrice from 'components/Catalog/Product/components/ProductPrice';

interface IProps {
    product: IProduct;
    labels: {
        logInForPrices: string;
        startingFrom: string;
        addToCart: string;
        quantity: string;
        readMore: string;
        outOfStock: string;
    };
}

const ProductActions: React.FunctionComponent<IProps> = (props) => {
    const { product, labels } = props;
    const { isLoggedIn } = useAuth();
    const dispatch = useDispatch();
    const [qty, setQty] = useState(1);

    const openLogin = () => {
        dispatch(closeOverlay({ name: 'all' }));
        dispatch(openOverlay({ name: 'login' }));
    };

    const [{ isPending }, addToCartRequest] = useMutation((data: IAddToCartRequestData) => AddToCartRequest(data));

    const handleAddToCart = () => {
        void addToCartRequest({
            sku: product.sku,
            qty: qty,
        }).then((res) => {
            if (res.status === HttpStatusCode.CREATED) {
                window.dispatchEvent(new CustomEvent('cart-altered'));
            } else {
                Toaster.addToast({
                    intent: 'danger',
                    text: res.body.message,
                });
            }
        });
    };

    return (
        <>
            {!isLoggedIn && (
                <button className="button button-discrete" onClick={openLogin}>
                    <span>
                        <span className="icon">
                            <Img iconName="icon_lock" />
                        </span>
                        {labels.logInForPrices}
                    </span>
                </button>
            )}
            {isLoggedIn && (
                <>
                    {product.isSaleable && (
                        <>
                            {product.showPrice && (
                                <div className="box-product__prices">
                                    <ProductPrice
                                        priceAsLowAsLabel={
                                            product.typeId === 'configurable' ? labels.startingFrom : undefined
                                        }
                                        finalPrice={product.specialPrice ?? product.price}
                                        price={product.originalPrice}
                                        productUnit={product.productUnit}
                                        pricePerUnit={product.pricePerUnit}
                                    />
                                </div>
                            )}
                            {product.typeId !== 'simple' && product.canAddToCart && (
                                <Button intent="secondary" title={labels.readMore} type="anchor" href={product.url} />
                            )}
                            {product.typeId === 'simple' && product.canAddToCart && (
                                <>
                                    <div className="box-product__quantity">
                                        <label htmlFor="q2">{labels.quantity}:</label>
                                        <input
                                            id="q2"
                                            type="number"
                                            step="1"
                                            aria-label={labels.quantity}
                                            value={qty > 0 ? qty : ''}
                                            onChange={(e) => setQty(parseInt(e.target.value))}
                                        />
                                    </div>
                                    <Button
                                        icon="plus"
                                        intent="primary"
                                        title={labels.addToCart}
                                        onClick={() => handleAddToCart()}
                                        loading={isPending}
                                        disabled={isPending}
                                    />
                                </>
                            )}
                        </>
                    )}
                    {!product.isSaleable && product.canAddToCart && (
                        <Button type="anchor" intent="secondary" title={labels.outOfStock} href={product.url} />
                    )}
                </>
            )}
        </>
    );
};

export default ProductActions;
