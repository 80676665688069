import * as React from 'react';
import Icon from 'ekaubamaja-ui/lib/Components/Icon/index';
import HeadingWithButtons from 'ekaubamaja-ui/lib/Components/HeadingWithButtons';
import classNames from 'classnames';
import Button from 'ekaubamaja-ui/lib/Components/Button/index';
import { useDispatch, useSelector } from 'react-redux';
import { IStoreState } from 'data/reducers/root';
import { closeOverlay, openOverlay } from 'data/actions/overlays';
import ButtonIcon from 'ekaubamaja-ui/lib/Components/ButtonIcon';
import { useState } from 'react';
import Overlay from 'ekaubamaja-ui/lib/Components/Overlay/index';
import Buttons from 'ekaubamaja-ui/lib/Components/Buttons';
import { includes } from 'lodash';
import { useMutation } from 'redux-query-react';
import ArchiveRequest from '../requests/ArchiveRequest';
import Toaster from 'ekaubamaja-ui/lib/Components/Toaster';

interface IProps {
    config: {
        clientUsers: IClientAccount[];
        labels: {
            accountLabel: string;
            addAccountLabel: string;
            statusLabel: string;
            nameLabel: string;
            roleLabel: string;
            emailLabel: string;
            activeLabel: string;
            confirmationRequiredLabel: string;
            archivedLabel: string;
            archiveAccountLabel: string;
            archiveAccountConfirmationLabel: string;
            archiveLabel: string;
            cancelLabel: string;
            clientCustomerArchiveFailedLabel: string;
        };
    };
}

interface IClientAccount {
    id: string;
    status: string;
    name: string;
    roleName: string;
    email: string;
}

enum StatusEnum {
    CUSTOMER_NOT_ACTIVE = 'customer_not_active',
    CUSTOMER_ACTIVE = 'customer_active',
    ACCOUNT_ARCHIVED = 'account_archived',
}

const manageAccounts: React.FunctionComponent<IProps> = (props) => {
    const [{}, clientArchiveRequest] = useMutation((clientCustomerId: string) => ArchiveRequest(clientCustomerId));
    const [activeSort, setActiveSort] = useState<string>('name');
    const [activeSortDirection, setActiveSortDirection] = useState<string>('asc');
    const [userIdToDelete, setUserIdToDelete] = useState<string>('');
    const dispatch = useDispatch();
    const { config } = props;
    const { labels } = config;

    const { openOverlays } = useSelector((state: IStoreState) => state.overlaysReducer);

    const sortByType = (sortType) => {
        setActiveSortDirection(activeSortDirection === 'asc' ? 'desc' : 'asc');
        setActiveSort(sortType);
    };

    const getSortIcon = (sortField: string, activeSort: string, activeSortDirection: string) => {
        if (activeSortDirection === 'asc' && activeSort === sortField) {
            return 'arrow1-up';
        }

        return 'arrow1-down';
    };

    const closeConfirm = () => {
        setUserIdToDelete('');
        dispatch(closeOverlay({ name: 'all' }));
    };
    const openConfirm = (userId: string) => {
        setUserIdToDelete(userId);
        dispatch(openOverlay({ name: 'confirm' }));
    };

    const deleteAccount = () => {
        clientArchiveRequest(userIdToDelete).then((response) => {
            if (response.body?.success) {
                window.location.reload();
            } else {
                Toaster.addToast({
                    intent: 'danger',
                    text: labels.clientCustomerArchiveFailedLabel,
                });
            }
        });
        closeConfirm();
    };

    return (
        <React.Fragment>
            <HeadingWithButtons
                title={labels.accountLabel}
                level={2}
                buttons={[
                    {
                        icon: 'plus',
                        title: labels.addAccountLabel,
                        intent: 'primary',
                        type: 'anchor',
                        href: '/b2bclient/account/create',
                    },
                ]}
            />
            <table className="data01">
                <thead>
                    <tr>
                        <th>
                            {labels.statusLabel}{' '}
                            <ButtonIcon
                                onClick={() => {
                                    sortByType('status');
                                }}
                                icon={getSortIcon('status', activeSort, activeSortDirection)}
                                intent={activeSort === 'status' ? 'primary' : 'default'}
                                size="default"
                            />
                        </th>
                        <th>
                            {labels.nameLabel}{' '}
                            <ButtonIcon
                                onClick={() => {
                                    sortByType('name');
                                }}
                                icon={getSortIcon('name', activeSort, activeSortDirection)}
                                intent={activeSort === 'name' ? 'primary' : 'default'}
                                size="default"
                            />
                        </th>
                        <th>
                            {labels.roleLabel}{' '}
                            <ButtonIcon
                                onClick={() => {
                                    sortByType('roleName');
                                }}
                                icon={getSortIcon('roleName', activeSort, activeSortDirection)}
                                intent={activeSort === 'roleName' ? 'primary' : 'default'}
                                size="default"
                            />
                        </th>
                        <th>
                            {labels.emailLabel}{' '}
                            <ButtonIcon
                                onClick={() => {
                                    sortByType('email');
                                }}
                                icon={getSortIcon('email', activeSort, activeSortDirection)}
                                intent={activeSort === 'email' ? 'primary' : 'default'}
                                size="default"
                            />
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {props.config.clientUsers
                        .sort((a, b) =>
                            a[activeSort] > b[activeSort]
                                ? activeSortDirection === 'asc'
                                    ? -1
                                    : 1
                                : activeSortDirection === 'asc'
                                  ? 1
                                  : -1,
                        )
                        .map((clientUser, i) => (
                            <tr key={i}>
                                <td>
                                    <span
                                        className={classNames('tag', {
                                            success: clientUser.status === StatusEnum.CUSTOMER_ACTIVE,
                                            warn: clientUser.status === StatusEnum.CUSTOMER_NOT_ACTIVE,
                                            danger: clientUser.status === StatusEnum.ACCOUNT_ARCHIVED,
                                        })}
                                    >
                                        {clientUser.status === StatusEnum.CUSTOMER_ACTIVE && <>{labels.activeLabel}</>}
                                        {clientUser.status === StatusEnum.CUSTOMER_NOT_ACTIVE && (
                                            <>{labels.confirmationRequiredLabel}</>
                                        )}
                                        {clientUser.status === StatusEnum.ACCOUNT_ARCHIVED && (
                                            <>{labels.archivedLabel}</>
                                        )}
                                    </span>
                                </td>
                                <td>{clientUser.name}</td>
                                <td>{clientUser.roleName}</td>
                                <td>{clientUser.email}</td>
                                <td>
                                    <Button
                                        title={labels.archiveAccountLabel}
                                        icon="user-remove"
                                        intent="discrete"
                                        onClick={() => {
                                            openConfirm(clientUser.id);
                                        }}
                                    />
                                </td>
                            </tr>
                        ))}
                </tbody>
            </table>
            <Overlay
                isOpen={includes(openOverlays, 'confirm')}
                doClose={closeConfirm}
                layout="dialog"
                size="small"
                className="epharma-dialog"
                customHeader={
                    <div className="epharma-dialog-heading">
                        <button onClick={closeConfirm}>
                            <Icon kind="close02" width={16} height={16} />
                        </button>
                    </div>
                }
            >
                <div className="epharma-dialog-content">
                    <h2>{labels.archiveAccountLabel}</h2>
                    <p>{labels.archiveAccountConfirmationLabel}</p>
                    <div className="product-actions">
                        <Buttons className="full margt">
                            <Button title={labels.archiveLabel} intent="primary" onClick={deleteAccount} />
                            <Button title={labels.cancelLabel} intent="secondary" onClick={closeConfirm} />
                        </Buttons>
                    </div>
                </div>
            </Overlay>
        </React.Fragment>
    );
};

export default manageAccounts;
