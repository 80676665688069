import React, { useRef } from 'react';
import FormList from 'ekaubamaja-ui/lib/Components/FormList';
import { useFormik } from 'formik';
import FormListItem from 'ekaubamaja-ui/lib/Components/FormListItem';
import ControlInput from 'ekaubamaja-ui/lib/Components/ControlInput';
import * as Yup from 'yup';
import Button from 'ekaubamaja-ui/lib/Components/Button';
import Buttons from 'ekaubamaja-ui/lib/Components/Buttons';
import ControlPassword from 'components/GeneralForm/Field/ControlPassword';
import IPasswordStrengthConfig from 'components/GeneralForm/Interfaces/IPasswordStrengthConfig';
import IPasswordConfig from 'components/GeneralForm/Interfaces/IPasswordConfig';
import validatePasswordMinCharacterSets from '../../../../helpers/passwordValidator';
import ControlPhone from 'components/GeneralForm/Field/ControlPhone';
import { ICustomerData, IFormConfig, ILabels } from 'components/Customer/SelfService/interfaces/IAccountForm';
import CookieConsent, { ICookieConsentForm } from 'components/Gdpr/Account/Form/CookieConsent';
import Newsletter, { INewsletterForm } from 'components/Newsletter/Account/Form/Newsletter';
import SystemNotifications from 'ekaubamaja-ui/lib/Applications/MagnumB2B/Components/SystemNotifications';
import SystemNotification from 'ekaubamaja-ui/lib/Applications/MagnumB2B/Components/SystemNotification';
import RenderHTML from 'ekaubamaja-ui/lib/Components/RenderHTML';

interface IProps {
    config: {
        initialValues: ICustomerData;
        labels: ILabels;
        formConfig: IFormConfig;
        passwordConfig: IPasswordConfig;
        cookieConsentConfig?: ICookieConsentForm;
        newsletterConfig?: INewsletterForm;
        passwordStrengthConfig: IPasswordStrengthConfig;
        veterinaryCodeRequired: boolean;
        clientAddress: string;
    };
}

const Edit: React.FunctionComponent<IProps> = (props) => {
    const { config } = props;
    const { passwordConfig, labels } = config;
    const { labels: passwordLabels } = passwordConfig;
    const additionalValidation = {};

    const formElement = useRef<HTMLFormElement>(null);

    if (config.veterinaryCodeRequired) {
        Object.assign(additionalValidation, {
            vet_licence_code: Yup.string().nullable().required(labels.requiredField),
        });
    }

    const FormValidationSchema = {
        firstname: Yup.string().nullable().required(labels.requiredField),
        lastname: Yup.string().nullable().required(labels.requiredField),
        customer_phone: Yup.number().nullable().required(labels.requiredField),
        email: Yup.string().nullable().email().required(labels.requiredField),
        personal_id: Yup.number().nullable().required(labels.requiredField),
        password: Yup.string()
            .nullable()
            .min(config.passwordConfig.minLength, passwordLabels.minLengthLabel)
            .test('password-min-character-sets', passwordLabels.minCharacterSetLabel, (value) =>
                value ? validatePasswordMinCharacterSets(value, passwordConfig.requiredCharacterClasses) : true,
            ),
        password_confirmation: Yup.string()
            .nullable()
            .when('password', {
                is: (password: string | undefined) => (password ? password.length > 0 : false),
                then: Yup.string().nullable().required(labels.requiredField),
            })
            .oneOf([Yup.ref('password'), null], passwordLabels.passwordMatchLabel),
        ...additionalValidation,
    };

    const formik = useFormik({
        initialValues: config.initialValues,
        validationSchema: Yup.object().shape(FormValidationSchema),
        validateOnChange: false,
        validateOnBlur: true,

        onSubmit: () => {
            if (formElement.current) {
                formElement.current.submit();
            }
        },
    });

    const isFormValid = () => {
        return Object.keys(formik.errors).length > 0;
    };

    return (
        <>
            <h2>{labels.contactInformation}</h2>
            <section>
                <p>{labels.requiredFields}</p>

                {isFormValid() && (
                    <SystemNotifications inline compact>
                        <SystemNotification intent="danger">
                            <RenderHTML html={labels.reCheckFormFields} nowrapper />
                        </SystemNotification>
                    </SystemNotifications>
                )}
                <form
                    className="margt"
                    ref={formElement}
                    action={config.formConfig.actionUrl}
                    method="post"
                    onSubmit={formik.handleSubmit}
                >
                    <input type="hidden" name="form_key" value={window.form_key} />
                    <FormList>
                        <FormListItem label={labels.firstname} required={true} error={formik.errors.firstname}>
                            <ControlInput
                                name="firstname"
                                value={formik.values.firstname}
                                onChange={formik.handleChange}
                                readonly={true}
                            />
                        </FormListItem>
                        <FormListItem label={labels.lastname} required={true} error={formik.errors.lastname}>
                            <ControlInput
                                name="lastname"
                                value={formik.values.lastname}
                                onChange={formik.handleChange}
                                readonly={true}
                            />
                        </FormListItem>
                        <FormListItem label={labels.phone} required={true} error={formik.errors.customer_phone}>
                            <ControlPhone
                                phoneNo={formik.values.customer_phone ?? ''}
                                phoneNoPrefix={
                                    formik.values.phone_prefix ?? window.generalConfig?.defaultPhonePrefix ?? ''
                                }
                                phonePrefixes={window.generalConfig?.phonePrefixes ?? []}
                                onChange={(result) => {
                                    void formik.setFieldValue('phone_prefix', result.phoneNoPrefix);
                                    void formik.setFieldValue('customer_phone', result.phoneNo);
                                }}
                            />
                            <input type="hidden" name="phone_prefix" value={formik.values.phone_prefix ?? ''} />
                            <input type="hidden" name="customer_phone" value={formik.values.customer_phone ?? ''} />
                        </FormListItem>
                        <FormListItem label={labels.email} required={true} error={formik.errors.email}>
                            <ControlInput
                                name="email"
                                value={formik.values.email ?? ''}
                                onChange={formik.handleChange}
                            />
                        </FormListItem>
                        <FormListItem label={labels.idCode} required={true} error={formik.errors.personal_id}>
                            <ControlInput
                                name="personal_id"
                                value={formik.values.personal_id ?? ''}
                                onChange={formik.handleChange}
                                readonly={true}
                            />
                        </FormListItem>
                        <FormListItem label={labels.address} required={false}>
                            <ControlInput name="client_address" value={config.clientAddress ?? ''} readonly={true} />
                        </FormListItem>

                        {config.veterinaryCodeRequired && (
                            <FormListItem
                                label={labels.vetLicenceCode}
                                required={true}
                                error={formik.errors.vet_licence_code}
                            >
                                <ControlInput
                                    name="vet_licence_code"
                                    value={formik.values.vet_licence_code ?? ''}
                                    onChange={formik.handleChange}
                                />
                            </FormListItem>
                        )}
                        {config.cookieConsentConfig?.isGdprEnabled && (
                            <CookieConsent config={config.cookieConsentConfig} />
                        )}
                        {config.newsletterConfig?.isNewsletterEnabled && (
                            <Newsletter config={config.newsletterConfig} />
                        )}
                        <FormListItem label={passwordLabels.changePasswordLabel} isSeparated={true} isFieldSet={true} />
                        <FormListItem label={passwordLabels.newPasswordLabel} error={formik.errors.password}>
                            <ControlPassword
                                name="password"
                                value={formik.values.password ?? ''}
                                onChange={formik.handleChange}
                                showPasswordButtonLabel={passwordLabels.showPasswordLabel}
                                passwordStrengthConfig={config.passwordStrengthConfig}
                                autoComplete={'new-password'}
                            />
                        </FormListItem>
                        <FormListItem
                            label={passwordLabels.confirmPasswordLabel}
                            error={formik.errors.password_confirmation}
                        >
                            <ControlPassword
                                name="password_confirmation"
                                value={formik.values.password_confirmation ?? ''}
                                onChange={formik.handleChange}
                                showPasswordButtonLabel={passwordLabels.showPasswordLabel}
                                autoComplete={'new-password'}
                            />
                        </FormListItem>
                    </FormList>
                    <Buttons>
                        <Button
                            type="submit"
                            intent="primary"
                            title={labels.submitForm}
                            onClick={() => {
                                window.scrollTo({
                                    top: 0,
                                    left: 0,
                                    behavior: 'smooth',
                                });
                            }}
                        />
                        <Button
                            type="anchor"
                            intent="secondary"
                            title={labels.cancelSubmitForm}
                            onClick={() => {
                                window.location.assign(config.formConfig.backUrl);
                            }}
                        />
                    </Buttons>
                </form>
            </section>
        </>
    );
};

export default Edit;
