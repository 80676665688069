import React, { useRef } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import FormKey from 'components/GeneralForm/Field/FormKey';
import FormList from 'ekaubamaja-ui/lib/Applications/MagnumB2B/Components/FormList/index';
import FormListItem from 'ekaubamaja-ui/lib/Applications/MagnumB2B/Components/FormListItem/index';
import ControlInput from 'ekaubamaja-ui/lib/Components/ControlInput';
import CheckboxList from 'ekaubamaja-ui/lib/Applications/MagnumB2B/Components/CheckboxList';
import RadioWithLabel from 'ekaubamaja-ui/lib/Components/RadioWithLabel';
import { B2BAccountRoleEnum } from 'components/B2BClient/enums/B2BAccountRoleEnum';
import Buttons from 'ekaubamaja-ui/lib/Components/Buttons';
import Button from 'ekaubamaja-ui/lib/Components/Button';
import SystemNotifications from 'ekaubamaja-ui/lib/Components/SystemNotifications';
import SystemNotification from 'ekaubamaja-ui/lib/Applications/MagnumB2B/Components/SystemNotification';

interface IProps {
    config: {
        formConfig: {
            actionUrl: string;
        };
        labels: {
            firstname: string;
            lastname: string;
            email: string;
            idCode: string;
            vetLicenceCode: string;
            role: string;
            veterinary: string;
            user: string;
            formSubmitLabel: string;
            invalidEmail: string;
            requiredField: string;
            checkFieldsLabel: string;
        };
    };
}

const B2BClientAccountFormCreate: React.FunctionComponent<IProps> = (props) => {
    const { config } = props;
    const { formConfig, labels } = config;

    const formElement = useRef<HTMLFormElement>(null);
    const formDataValidationSchema = Yup.object().shape({
        firstname: Yup.string().nullable().required(labels.requiredField),
        lastname: Yup.string().nullable().required(labels.requiredField),
        email: Yup.string().email(config.labels.invalidEmail).required(config.labels.requiredField),
        personal_id: Yup.string().nullable().required(labels.requiredField),
        role: Yup.string().nullable().required(labels.requiredField),
        is_veterinary: Yup.boolean(),
        vet_licence_code: Yup.string()
            .nullable()
            .when('is_veterinary', {
                is: true,
                then: Yup.string().nullable().required(config.labels.requiredField),
            }),
    });

    const formik = useFormik({
        initialValues: {
            firstname: '',
            lastname: '',
            personal_id: '',
            email: '',
            role: '',
            is_veterinary: false,
            vet_licence_code: '',
        },
        validationSchema: formDataValidationSchema,
        validateOnBlur: true,
        validateOnChange: false,
        onSubmit: () => {
            if (formElement.current) {
                formElement.current.submit();
            }
        },
    });

    const handleRoleChange = (role: B2BAccountRoleEnum) => {
        void formik.setFieldValue('is_veterinary', role === B2BAccountRoleEnum.VET);
        void formik.setFieldValue('role', role);
    };

    const hasErrors = () => {
        return Object.values(formik.errors).length !== 0;
    };

    return (
        <form
            ref={formElement}
            action={formConfig.actionUrl}
            method="post"
            className="margt"
            onSubmit={formik.handleSubmit}
        >
            <FormKey />

            {hasErrors() && (
                <SystemNotifications inline={true} compact={true}>
                    <SystemNotification intent="danger">
                        <p>{config.labels.checkFieldsLabel}</p>
                    </SystemNotification>
                </SystemNotifications>
            )}

            <FormList size="wide">
                <FormListItem label={labels.firstname} required={true} error={formik.errors.firstname}>
                    <ControlInput name="firstname" value={formik.values.firstname} onChange={formik.handleChange} />
                </FormListItem>
                <FormListItem label={labels.lastname} required={true} error={formik.errors.lastname}>
                    <ControlInput name="lastname" value={formik.values.lastname} onChange={formik.handleChange} />
                </FormListItem>
                <FormListItem label={labels.idCode} required={true} error={formik.errors.personal_id}>
                    <ControlInput name="personal_id" value={formik.values.personal_id} onChange={formik.handleChange} />
                </FormListItem>
                <FormListItem label={labels.email} required={true} error={formik.errors.email}>
                    <ControlInput name="email" value={formik.values.email} onChange={formik.handleChange} />
                </FormListItem>
                <FormListItem
                    isCheckboxes={true}
                    isFieldSet={true}
                    label={labels.role}
                    required={true}
                    error={formik.errors.role}
                >
                    <CheckboxList isInline={true}>
                        <RadioWithLabel
                            label={labels.veterinary}
                            value={formik.values.role}
                            checked={formik.values.role === B2BAccountRoleEnum.VET}
                            onChange={() => handleRoleChange(B2BAccountRoleEnum.VET)}
                        />
                        <RadioWithLabel
                            label={labels.user}
                            value={formik.values.role}
                            checked={formik.values.role === B2BAccountRoleEnum.REGULAR}
                            onChange={() => handleRoleChange(B2BAccountRoleEnum.REGULAR)}
                        />
                    </CheckboxList>
                    <input type="hidden" name="role" value={formik.values.role} />
                </FormListItem>
                {formik.values.is_veterinary && (
                    <FormListItem label={labels.vetLicenceCode} required={true} error={formik.errors.vet_licence_code}>
                        <ControlInput
                            name="vet_licence_code"
                            value={formik.values.vet_licence_code ?? ''}
                            onChange={formik.handleChange}
                        />
                    </FormListItem>
                )}
            </FormList>
            <Buttons layout="vertical-wide">
                <Button type="submit" intent="primary" title={labels.formSubmitLabel} />
            </Buttons>
        </form>
    );
};

export default B2BClientAccountFormCreate;
