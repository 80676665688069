import React from 'react';
import IProduct from 'components/Catalog/interfaces/IProduct';
import BoxProduct from 'components/Catalog/Product/List/BoxProduct';
import MultiCarousel from 'ekaubamaja-ui/lib/Components/MultiCarousel';

interface IProps {
    config: {
        title: string;
        products: IProduct[];
        url?: string;
        urlTitle?: string;
        shortLayout: boolean;
        labels: {
            addedToCart: string;
            logInForPrices: string;
            startingFrom: string;
            addToCart: string;
            quantity: string;
            readMore: string;
            outOfStock: string;
        };
    };
}

const ProductSlider: React.FunctionComponent<IProps> = (props) => {
    const { config } = props;
    const { products, labels } = config;

    return (
        <section className="frame-epharma__section">
            <div className="frame-epharma__section__limiter">
                <header className="frame-epharma__section__header">
                    <h2>
                        {config.title}
                        {config.url && (
                            <a href={config.url}>
                                <span>{config.urlTitle}</span>
                            </a>
                        )}
                    </h2>
                </header>
                <div className="products-carousel">
                    <div className="carousel carousel-inline has-arrows size-auto type-multiple">
                        <MultiCarousel
                            renderArrowsOutside
                            showArrows
                            type={config.shortLayout ? 'onethird' : 'productslider'}
                        >
                            {products.map((product) => (
                                <div key={product.id} className="layout-products__container">
                                    <BoxProduct product={product} labels={labels} />
                                </div>
                            ))}
                        </MultiCarousel>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ProductSlider;
