import React from 'react';
import CartProducts from 'components/CartView/components/CartProducts';
import useCartSummary from '../../hooks/useCartSummary';
import CartSideBar from 'components/CartView/components/CartSideBar';
import ProductCampaign from 'ekaubamaja-ui/lib/Applications/MagnumB2B/Components/ProductCampaign/index';
import CartShare, { ICartShareConfig } from 'components/CartShare/CartShare';
import LoadingContent from 'ekaubamaja-ui/lib/Components/LoadingContent';
import { ICartProduct } from 'components/CartView/interfaces/CartInterfaces';

interface IProps {
    config: {
        noProductsNotificationImage: string;
        checkoutUrl: string;
        labels: ICartLabels;
        cartShareConfig?: ICartShareConfig;
    };
}

export interface ICartLabels {
    cartLabel: string;
    medicineLabel: string;
    nonMedicineLabel: string;
    quantityLabel: string;
    totalLabel: string;
    removeLabel: string;
    updateLabel: string;
    close: string;
    invalidQuantity: string;
    cartSummaryLabel: string;
    sumLabel: string;
    checkoutLabel: string;
    noProductsInCart: string;
    enterStore: string;
    startShopping: string;
    here: string;
}

const CartView: React.FunctionComponent<IProps> = (props) => {
    const { cartTotals, products, isFinished } = useCartSummary();
    const { config } = props;
    const { labels, cartShareConfig } = config;

    const cartEmpty = cartTotals && !products.length;

    const groupProducts = (products: ICartProduct[]) => {
        const medicineProducts: ICartProduct[] = [];
        const nonMedicineProducts: ICartProduct[] = [];

        if (products) {
            products.forEach((product) => {
                if (product.isMedicine) {
                    medicineProducts.push(product);
                } else {
                    nonMedicineProducts.push(product);
                }
            });
        }

        return { medicineProducts, nonMedicineProducts };
    };
    const { medicineProducts, nonMedicineProducts } = groupProducts(products);

    if (!isFinished) {
        return <LoadingContent layout={'flex'} size={'large'} />;
    }

    return (
        <div className="layout-cart">
            <div className="layout-cart__main">
                {!cartEmpty ? (
                    <>
                        {medicineProducts.length > 0 && (
                            <CartProducts
                                products={medicineProducts}
                                title={labels.medicineLabel}
                                icon={'icon_meta02'}
                                labels={labels}
                            />
                        )}
                        {nonMedicineProducts.length > 0 && (
                            <CartProducts
                                products={nonMedicineProducts}
                                title={labels.nonMedicineLabel}
                                icon={'icon_meta05'}
                                labels={labels}
                            />
                        )}
                        {cartShareConfig && (
                            <div className="cart-actions">
                                <div className="cart-actions__primary">
                                    <CartShare config={cartShareConfig} />
                                </div>
                            </div>
                        )}
                    </>
                ) : (
                    <>
                        <ProductCampaign
                            theme="info"
                            img={config.noProductsNotificationImage}
                            button={{
                                intent: 'primary',
                                type: 'anchor',
                                href: '/',
                                title: labels.enterStore,
                            }}
                        >
                            <h2>{labels.noProductsInCart}</h2>
                            <p>
                                {`${labels.startShopping} `}
                                <a href="/">{labels.here}</a>
                            </p>
                        </ProductCampaign>
                    </>
                )}
            </div>
            {!cartEmpty && (
                <>
                    <CartSideBar labels={labels} checkoutUrl={config.checkoutUrl} />
                    <CartSideBar isMobileSticky labels={labels} checkoutUrl={config.checkoutUrl} />
                </>
            )}
        </div>
    );
};

export default CartView;
